import React from "react";
import { TypeAnimation } from "react-type-animation";
import "./CodeBlocks.css";
import { Tilt } from "react-tilt";

function CodeBlocks({
        codeblock, backgroundGradient}){
    return(
        <div className= "topdiv">
            {/* Section 2 */}
            <Tilt
                className="Tilt"
                options={{
                max: 25,
                perspective: 1000,
                scale: 0.875,
                speed: 200,
                transition: true,
                reset: true,
        }}
      >
                <div className="backGround code-border">
                    {backgroundGradient}

                    {/* Indexing */}
                    <div className = "indexing">
                    <p>1</p>
                    <p>2</p>
                    <p>3</p>
                    <p>4</p>
                    <p>5</p>
                    <p>6</p>
                    <p>7</p>
                    <p>8</p>
                    <p>9</p>
                    <p>10</p>
                    <p>11</p>
                    <p>12</p>
                    </div>

                    {/* Codes */}
                    {/* {`w-[90%] flex flex-col gap-2 font-bold font-mono ${codeColor} pr-1` }  */}
                    <div className = "code-container type-animation">
                        <TypeAnimation
                            sequence={[codeblock, 5000, ""]}
                            cursor={true}
                            repeat={Infinity}
                            style = {
                                {
                                    whiteSpace: "pre-line",
                                    display: "block",
                                }
                            }
                            omitDeletionAnimation={true}
                        />
                    </div>
                </div>
            </Tilt>     
        </div>
    );
}

export default CodeBlocks;


