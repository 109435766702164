import React from "react";
import styled from "styled-components";
import { VerticalTimelineElement } from "react-vertical-timeline-component";
import { Image } from "antd";
// Styled components for the Hackathon card
const Top = styled.div`
  width: 100%;
  display: flex;
  max-width: 100%;
  gap: 12px;
`;

const StyledImage = styled.img`
  height: 50px;
  border-radius: 10px;
  margin-top: 4px;
  @media only screen and (max-width: 768px) {
    height: 40px;
  }
`;

const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.text_primary + 99};
  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const Position = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.text_secondary + 99};
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

const Date = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.text_secondary + 80};
  @media only screen and (max-width: 768px) {
    font-size: 10px;
  }
`;

const Description = styled.div`
  font-size: 15px;
  font-weight: 400;
  color: ${({ theme }) => theme.text_primary + 99};
  margin-bottom: 10px;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

const ImageSlider = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 8px;
  padding-top: 8px;

  & img {
    width: 100%;
    height: 100px;
    border-radius: 8px;
    object-fit: cover;
  }
`;


const HackathonCard = ({ hackathon }) => {
  return (
    <VerticalTimelineElement
      icon={
        <img
          width="100%"
          height="100%"
          alt={hackathon.title}
          style={{ borderRadius: "50%", objectFit: "cover" }}
          src={hackathon.image}
        />
      }
      contentStyle={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        background: "#1d1836",
        color: "#fff",
        boxShadow: "rgba(23, 92, 230, 0.15) 0px 4px 24px",
        backgroundColor: "rgba(17, 25, 40, 0.83)",
        border: "1px solid rgba(255, 255, 255, 0.125)",
        borderRadius: "6px",
      }}
      contentArrowStyle={{
        borderRight: "7px solid rgba(255, 255, 255, 0.3)",
      }}
      date={hackathon.date}
    >
      <Top>
        <StyledImage src={hackathon.image} />
        <Body>
          <Title>{hackathon.title}</Title>
          <Position>{hackathon.position}</Position>
          <Date>{hackathon.date}</Date>
        </Body>
      </Top>

      <Description>{hackathon.description}</Description>

      {/* Event images slider */}
      {hackathon.eventImages && hackathon.eventImages.length > 0 && (
        <ImageSlider>
          {hackathon.eventImages.map((imgSrc, idx) => (
            <Image key={idx} src={imgSrc} alt={`${hackathon.title} event image ${idx + 1}`} />
          ))}
        </ImageSlider>
      )}
    </VerticalTimelineElement>
  );
};

export default HackathonCard;
